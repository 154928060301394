<template>
  <div class="wrapper">
    <div class="content">
      <h1>Soluciones</h1>
      <div class="container">
        <div class="text">
          <h1>Optimización de compra</h1>
          <p>
            Estudiamos caso a caso las posibilidades de optimización disponibles
            que mejor se adaptan a las necesidades de tu empresa. Planteamos
            cambios en áreas como la modalidad de compra, los precios o la
            potencia resultando en un ahorro medio superior al 20%.
          </p>
        </div>
        <div class="illustration">
          <img
            src="@/assets/images/illustrations/soluciones_ahorro.svg"
            alt=""
          />
        </div>
      </div>
      <div class="container">
        <div class="text">
          <h1>Seguimiento periódico</h1>
          <p>
            Cuando la estrategia de compra quede definida, hacemos un
            seguimiento del consumo para validar el ahorro. Además, tramitamos
            las reclamaciones pertinentes y ofrecemos servicios de consultoría
            para seguir reduciendo el consumo energético centrándonos en
            instalaciones de generación renovable.
          </p>
        </div>
        <div class="illustration">
          <img
            src="@/assets/images/illustrations/soluciones_optimizacion.svg"
            alt=""
          />
        </div>
      </div>
      <div class="container">
        <div class="text">
          <h1>Demanda flexible</h1>
          <p>
            Nuestro software de flexibilización de demanda energética estará
            disponible para nuestros cliente a finales de 2021, coincidiendo con
            el cambio normativo que permite al consumidor monetizar su
            flexibilidad. Pincha aquí si quieres que te mantengamos informado.
          </p>
        </div>
        <div class="illustration">
          <img
            src="@/assets/images/illustrations/soluciones_flexibilidad.svg"
            alt=""
          />
        </div>
      </div>
      <div class="container">
        <div class="text" style="width: 100%; text-align: center;">
          <h1 style="text-align: center;">Generación renovable</h1>
          <p style="text-align: center;">
           Realizamos estudios de consultoría energética para favorecer la generación propia de energía renovable entre nuestros clientes. Contamos con los proveedores de equipos de generación más reputados, con instaladores propios con una amplía experiencia y con precios altamente competitivos, ¡pídenos un presupuesto y compruébalo!
          </p>
        </div>
        <!-- <div class="illustration">
          <img
            src="@/assets/images/illustrations/soluciones_flexibilidad.svg"
            alt=""
          />
        </div> -->
      </div>

      <router-link to="/contacto"><button>Quiero unirme</button></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Solutions",
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables";
.wrapper {
  margin-top: 120px;
  padding-top: 50px;
  padding-bottom: 200px;
  background-image: url(../assets/images/wavy_background_bottom_lay.svg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .content {
    .container {
      width: calc(100% - 80px);
      padding: 15px 40px;
      background-color: $dirty-white;
      margin-bottom: 75px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      h1,
      h2,
      p {
        text-align: left;
      }
      .text {
        width: 60%;
      }
      .illustration {
        width: 30%;
        margin: auto;
      }
      @media screen and (max-width: 800px) {
        .text {
          width: 100%;
        }
        .illustration {
          width: 45%;
        }
      }
    }
  }
}
</style>
